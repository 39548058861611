import { GfppActionType } from './onEvent';

export const setComponentsManifest = ({
  translations: { displayName, mainAction1Label },
}) => ({
  controllersStageData: {
    WixChat: {
      default: {
        displayName,
        gfpp: {
          desktop: {
            mainAction1: { actionId: GfppActionType.SETTIGNS, label: mainAction1Label },
            iconButtons: {
              link: 'HIDE',
              connect: 'HIDE',
            },
            helpId: '08beca3b-441f-401f-a6cd-568916bb90f6'
          },
        },
      },
    },
  },
})
