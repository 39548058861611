import i18next from "i18next";
import i18nextXHRBackend from "i18next-xhr-backend";
import { getBaseUrl } from ".";

const FALLBACK_TRANSLATIONS_FILE =
  "//static.parastorage.com/services/wix-chat-button/1.1.0";
const BASE_URL = `https://${getBaseUrl() || FALLBACK_TRANSLATIONS_FILE}/`;

const i18nextCore = (locale, cb) => {
  i18next.use(i18nextXHRBackend).init(
    {
      lng: locale,
      fallbackLng: "en",
      keySeparator: "$",
      interpolation: {
        escapeValue: false,
      },
      react: {
        wait: true,
        useSuspense: false,
      },
      backend: {
        loadPath: `${BASE_URL}assets/locale/messages_{{lng}}.json`,
        crossDomain: true,
      },
    },
    (err, t) => cb(err, t, i18next)
  );
};

export default function i18n({ locale }): Promise<{ t; i18nInstance }> {
  return new Promise((resolve, reject) => {
    i18nextCore(locale, (err, t, i18nInstance) =>
      err ? reject(err) : resolve({ t, i18nInstance })
    );
  });
}
