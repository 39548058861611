import { getControllerStructure } from './structures/controller';
import { getButtonStructure } from './structures/button';
import translations from '../utils/translations';
import { EditorSDK } from '@wix/platform-editor-sdk';

export class CoreApi {
  public editorSDK: EditorSDK
  public appDefinitionId: string

  constructor(editorSDK: EditorSDK, appDefinitionId: string) {
    this.editorSDK = editorSDK
    this.appDefinitionId = appDefinitionId
  }
  getLocale = () => this.editorSDK.environment.getLocale()

  save = () => this.editorSDK.document.save()

  getChildren = (componentRef) =>
    this.editorSDK.components.getChildren(this.appDefinitionId, { componentRef })

  getComponentData = (sourceComponentRef) =>
    this.editorSDK.components.data.get(this.appDefinitionId, { componentRef: sourceComponentRef })

  updateComponentData = (sourceComponentRef, data) =>
    this.editorSDK.components.data.update(this.appDefinitionId, { componentRef: sourceComponentRef, data })

  getCurrentPage = () => this.editorSDK.pages.getCurrent(this.appDefinitionId)

  connect = (
    controllerRef,
    connectToRef,
    role,
  ) => {
    return this.editorSDK.controllers.connect(this.appDefinitionId, {
      connectToRef,
      controllerRef,
      role,
      isPrimary: true,
    })
  }

  connectButton = async (controllerRef, buttonRole) => {
    const [button] = await this.getChildren(controllerRef)
    await this.connect(controllerRef, button, buttonRole)
  }

  addController = (pageRef) => {
    const buttonStructure = getButtonStructure(
      translations.t('chatButton.label'),
    );
    return this.editorSDK.components.add(this.appDefinitionId, {
      pageRef,
      role: 'ChatButton',
      componentDefinition: getControllerStructure(
        this.appDefinitionId,
        buttonStructure,
      ),
    } as any)
  }

  controllerAlreadyExists = async (controllerType ) => {
    const controllers = await this.editorSDK.controllers.listAllControllers(this.appDefinitionId)
    for (const controller of controllers) {
      const data = await this.editorSDK.controllers.getData(this.appDefinitionId, controller)
      if (data.type === controllerType) {
        return true
      }
    }
    return false
  }

  selectIconFromMediaPanel = async () => {
    const [image] = await this.editorSDK.editor.openMediaPanel(this.appDefinitionId, {
      isMultiSelect: false,
      mediaType: 'SHAPE_BASIC' as any,
    });
    return image;
  };

  openComponentPanel = (options) => this.editorSDK.editor.openComponentPanel(this.appDefinitionId, options)

}
