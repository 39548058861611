import { editorReadyImp } from './editorReady';
import { setComponentsManifest } from './manifest';
import { onEventImp } from './onEvent';
import translations from '../utils/translations';
import { EditorPlatformApp, EditorSDK } from '@wix/platform-editor-sdk';
import { CoreApi } from './coreApi';

let coreApi: CoreApi;

export const editorReady = async (editorSDK: EditorSDK, token: string, payload) => {
  coreApi = new CoreApi(editorSDK, token)
  return editorReadyImp(coreApi, payload)
}

export const onEvent: EditorPlatformApp['onEvent'] = async ({
  eventType,
  eventPayload,
}) => {
  return onEventImp(coreApi, eventType, eventPayload);
};

export const getAppManifest = () => {
  return setComponentsManifest({
    translations: {
      displayName: translations.t('chatButton.displayName'),
      mainAction1Label: translations.t('chatButton.settings'),
    },
  })
}
