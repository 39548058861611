const getEnvName = () => process.env.NODE_ENV || '';

export const isProduction = () =>
  getEnvName() !== 'development' && getEnvName() !== 'test'

export const getAppUrl = (origin = 'editor-app') =>
  isProduction()
    ? `https://static.parastorage.com/services/wix-chat-button/${getAppVersion()}/${origin}.bundle.min.js`
    : `https://localhost:3205/${origin}.bundle.js`

export const getBaseUrl = () => {
  const [url] = getAppUrl().match(/((?:\/[^\/]+)+)(?=\/[^\/]+)/) || [null]
  return `${(url && url.substring(1)) || ''}`
}

export const getAppVersion = () => {
  // we are not actually accessing window here, it's replaced via webpack's define plugin
  if (isProduction()) {
    //@ts-ignore
    return window.__CI_APP_VERSION__.replace('-SNAPSHOT', '')
  }

  return 'local-development'
};

// export const getStaticsBaseUrl = () => `${getBaseUrl()}/statics`;
