import { CoreApi } from './coreApi'
import { getBaseUrl } from '../utils';
import translations from '../utils/translations';

export enum GfppActionType {
  SETTIGNS = 'settings'
}

export const onEventImp = async (coreApi: CoreApi, eventType, eventPayload) => {
  if (eventType === 'widgetGfppClicked') {
    switch (eventPayload.id) {
      case GfppActionType.SETTIGNS:
        const { componentRef } = eventPayload;
        const [button] = await coreApi.getChildren(componentRef);

        const options = {
          title:  translations.t('settings.title'), //TODO trans
          initialData: {
            componentRef: button
          },
          width: 300,
          height: 443,
          helpId: '08beca3b-441f-401f-a6cd-568916bb90f6',
        };

        return coreApi.openComponentPanel({
          url: `//${getBaseUrl()}/settings.html`,
          ...options,
          componentRef: button
        })
      default:
        return
    }
  }
}
