import i18n from './i18n';

class TranslationsInstance {
  private _t;

  constructor() {
    this.editorInit = this.editorInit.bind(this)
  }

  async editorInit(locale) {
    const { t } = await i18n({ locale });
    this._t = t
  }

  t = (key, options = {}) => {
    if (!this._t) {
      throw new Error('Translation service was not initialized yet!');
    }
    return this._t(key, options)
  }
}

export default new TranslationsInstance()
