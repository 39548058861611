import { ComponentDefinition } from '@wix/platform-editor-sdk';

const getControllerStructure = (
  appDefinitionId,
  components
): Partial<ComponentDefinition> => {
  return {
    componentType: 'platform.components.AppWidget',
    data: {
      controllerType,
      name: 'WixChat',
      applicationId: appDefinitionId,
    },
    layout: {
      width: 142,
      height: 48,
    },
    components: [components],
  };
};

export { getControllerStructure };

export const controllerType = 'WixChat';
