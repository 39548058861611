import translations from '../utils/translations';
import { buttonRole } from './structures/button';
import { controllerType } from './structures/controller';
import { CoreApi } from './coreApi';

export const editorReadyImp = async (coreApi: CoreApi,
  { firstInstall, origin },
) => {
  try {
    const [pageRef, locale, controllerExists] = await Promise.all([
      coreApi.getCurrentPage(),
      coreApi.getLocale(),
      coreApi.controllerAlreadyExists(controllerType),
      coreApi.save()
    ]);

    await translations.editorInit(locale);
    const originType = origin && origin.info && origin.info.type

    // First install from AppMarket
    if (!controllerExists && firstInstall && originType === 'APP_MARKET') {
      const controllerRef = await coreApi.addController(pageRef);
      await coreApi.connectButton(controllerRef, buttonRole)
    }
  } catch (error) {
    console.error(error);
  }
}
